<template>
    <login-layout>
        <div @click="errorMessage=''">
            <h5 class="text-primary mb-4">Login</h5>
            <form @submit.prevent="loginClicked">
                <label class="label">Email</label>
                <validated-input placeholder="Enter Email ID" name="Email ID" type="email" class="mb-0 text-secondary"
                                 v-model="model.email"
                                 :disabled="loading" :rules="rules.email"/>
                <label>Password</label>
                <validated-input placeholder="Enter Password" type="password"
                                 v-model="model.password" class="text-secondary mb-1"
                                 :disabled="loading" :rules="rules.password"/>
                <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                <p @click="$router.push('/forgot-password/')" class="mb-4 link text-secondary">Forgot Your Password?</p>
                <div class="text-center">
                    <btn class="pl-5 pr-5" text="Login" loading-text="Validating..." size="sm"
                         :disabled="loading" :loading="loading"/>
                </div>
            </form>
        </div>
    </login-layout>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import LoginLayout from './LoginPageLayout';
import axios from 'secure-axios';

export default {
    name: 'Login',
    components: { LoginLayout },
    data () {
        return {
            loading: false,
            loginUrl: urls.auth.login,
            errorMessage: '',
            model: {
                email: '',
                password: ''
            },
            rules: {
                email: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async loginClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            if (json.name) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                that.setUser(json);
                that.$router.push(redirectUrl || { path: '/admin/' });
                that.loading = false;
            } else {
                that.errorMessage = 'Invalid Credentials';
                that.loading = false;
            }
            that.loading = false;
            localStorage.clear();
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
